import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Image = (props) => (
    <StaticQuery
    query={graphql`
      query {
        images: allImageSharp {
          edges {
            node {
              gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              fixed {
                originalName
              }
            }
          }
        }
      }
    `}
  
      render={(data) => {
        const images = data.images.edges

        const imageObj = images.find(n => {
            return n.node.fixed.originalName.includes(props.filename);
        });
        var GImage = ""
        if (imageObj) {
            GImage = getImage(imageObj.node)
        }

        if (!GImage) { return null; }
        return (
            <GatsbyImage image={GImage} className={`speaker-image`} />
        );
      }}
    />
  )
 export default Image