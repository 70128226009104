// extracted by mini-css-extract-plugin
export var topTitle = "index-module--topTitle--1t5Cg";
export var topTitleFrame = "index-module--topTitleFrame--27ZCD";
export var title = "index-module--title--2SlN0";
export var logos = "index-module--logos--3__o4";
export var peopleBG = "index-module--peopleBG--3sLL6";
export var startDateFrame = "index-module--startDateFrame--3NAPw";
export var overviewFrame = "index-module--overviewFrame--2czMS";
export var overviewTextFrame = "index-module--overviewTextFrame--Nju7U";
export var overviewBgFrame = "index-module--overviewBgFrame--MOtgp";
export var section = "index-module--section--1dSbN";
export var sectionFrame = "index-module--sectionFrame--yDXMm";
export var conceptFrame = "index-module--conceptFrame--1kAGG";
export var conceptTextFrame = "index-module--conceptTextFrame--3O2o4";
export var buttonFrame = "index-module--buttonFrame--1X5cj";
export var speakersFrame = "index-module--speakersFrame--3tRrr";
export var speakersInfo = "index-module--speakersInfo--3UGzD";
export var members = "index-module--members--21D2d";
export var membersFrame = "index-module--membersFrame--19XM4";
export var header = "index-module--header--24iB1";
export var NGCILogo = "index-module--NGCILogo--2M3Yn";
export var TokyoUnivLogo = "index-module--TokyoUnivLogo--JZScF";